import { NgIf, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { UploadedFileType } from '../../../modules/wizard/models/uploaded-file.type';

@Component({
  selector: 'hmt-uploaded-document',
  templateUrl: './uploaded-document.component.html',
  styleUrl: './uploaded-document.component.scss',
  standalone: true,
  imports: [FormsModule, MatSlideToggle, NgIf, NgOptimizedImage],
})
export class UploadedDocumentComponent {
  @Input() file!: UploadedFileType;
  @Output() activateFile = new EventEmitter<UploadedFileType>();

  toggleActive(): void {
    if (!this.file.isActive) {
      this.activateFile.emit(this.file);
    }
  }
}
