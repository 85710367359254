<!-- document-upload-category.component.html -->
<div class="document-category">
  <div class="document-category-header">
    <p>{{ categoryName }}</p>
    <div class="document-category-header-right">
      <img *ngIf="files.length > 0" ngSrc="../../../../assets/icons/correct.svg" alt="" height="20" width="20" />
      <button
        mat-raised-button
        type="button"
        color="primary"
        class="upload-button"
        (click)="triggerFileInput()"
        [disabled]="viewOnly">
        <img ngSrc="../../../../assets/icons/upload-file.svg" alt="Upload Icon" height="20" width="20" />
        Upload
      </button>
      <input type="file" #fileInput (change)="uploadFile($event)" hidden />
    </div>
  </div>
  <div *ngIf="files.length > 0" class="uploaded-files-container">
    <div *ngFor="let file of files">
      <hmt-uploaded-document [file]="file" (activateFile)="setActiveFile($event)"></hmt-uploaded-document>
    </div>
  </div>
</div>
